import React, { useState, useEffect } from "react";
import fetchJsonp from "fetch-jsonp";

require("es6-promise").polyfill();

const SmartFeedJobAlerts = (props) => {
  const [jaDetails, setJaDetails] = useState({
    CompanyGroupName: props.CompanyGroupName,
    EmailAddress: "",
    FirstName: "",
    LastName: "",
    keywords: "",
    geoLocation: "",
    Location: [],
    Region: [],
    Category: [],
    Industry: [],
    JobType: [],
    JobTime: [],
    MailFrequency: 86400000,
    acceptGDPR: false,
  });

  const groupOrIdParam = props.group ? "group" : "id";

  const [filterData, setFilterData] = useState({});
  const [locationData, setLocationData] = useState([]);
  const [regionData, setRegionData] = useState([]);
  const [categoryData, setCategoryData] = useState([]);
  const [industryData, setIndustryData] = useState([]);
  const [jobTypeData, setJobTypeData] = useState([]);
  const [jobTimeData, setJobTimeData] = useState([]);

  const [showGDPR, setShowGDPR] = useState(false);
  const [GDPRStatement, setGDPRStatement] = useState("");
  const [apiResponse, setApiResponse] = useState("NA");

  useEffect(() => {
    if (props.group) {
      if (props.location) {
        getApiData("location", "GetLocationListByGroup", setLocationData);
      }
      if (props.region) {
        getApiData("region", "GetRegionListByGroup", setRegionData);
      }
      if (props.category) {
        getApiData("category", "GetCategoryListByGroup", setCategoryData);
      }
      if (props.industry) {
        getApiData("industry", "GetIndustryListByGroup", setIndustryData);
      }
      if (props.jobType) {
        getApiData("JobType", "GetJobTypeListByGroup", setJobTypeData);
      }
      if (props.jobTime) {
        getApiData("JobTime", "GetJobTimes", setJobTimeData);
      }
    } else {
      if (props.location) {
        getApiData("location", "GetAllLocationsListByCompany", setLocationData);
      }
      if (props.region) {
        getApiData("region", "GetAllRegionsListByCompany", setRegionData);
      }
      if (props.category) {
        getApiData(
          "category",
          "GetAllCategoriesListByCompany",
          setCategoryData
        );
      }
      if (props.industry) {
        getApiData(
          "industry",
          "GetAllIndustriesListByCompany",
          setIndustryData
        );
      }
      if (props.jobType) {
        getApiData("JobType", "GetJobTypes", setJobTypeData);
      }
      if (props.jobTime) {
        getApiData("JobTime", "GetJobTimes", setJobTimeData);
      }
    }

    getGDPRApiData();
  }, []);

  const changeGDPRVal = () => {
    let temp = { ...jaDetails };

    temp.acceptGDPR = !temp.acceptGDPR;

    setJaDetails(temp);
  };

  const getApiData = (name, apiCall, setState) => {
    if (apiCall === "GetJobTimes") {
      const jsonList = [
        "Full Time",
        "Part Time",
        "Term Time",
        "Variable Hours",
        "Sessional",
        "Fractional Hours",
      ];

      const uniqueArray = [];

      uniqueArray.push("All");

      jsonList.map((item, i) => {
        if (!uniqueArray.includes(item)) {
          uniqueArray.push(item);
        }
      });

      setState(uniqueArray);
    } else {
      fetchJsonp(
        props.siteConfig.sfAPIEndpoint +
          "/CareerPage/" +
          apiCall +
          "?" +
          groupOrIdParam +
          "=" +
          props.apiKey +
          "&region=All",
        {
          timeout: 15000,
        }
      )
        .then(function (response) {
          return response.json();
        })
        .then(function (jsonList) {
          let uniqueArray = [];

          uniqueArray.push("All");

          jsonList.map((item, i) => {
            if (!uniqueArray.includes(item)) {
              uniqueArray.push(item);
            }
          });

          setState(uniqueArray);
        });
    }
  };

  const getGDPRApiData = (name, apiCall, setState) => {
    let gdprID = props.apiKey;

    if (props.group) {
      gdprID = props.gdprKey;
    }

    fetchJsonp(
      props.siteConfig.sfAPIEndpoint +
        "/CareerPage/GetGdprStatementByCompanyId?encodedCompanyId=" +
        gdprID,
      {
        timeout: 15000,
      }
    )
      .then(function (response) {
        return response.json();
      })
      .then(function (gdprStatement) {
        setGDPRStatement(gdprStatement);
      });
  };

  useEffect(() => {
    props.trackEvent({
      eventCategory: "JobAlertsPage",
      eventAction: "initalised",
    });
  }, []);

  const subscribeToJobAlerts = () => {
    const serialize = function (obj) {
      var str = [];
      for (var p in obj)
        if (obj.hasOwnProperty(p)) {
          str.push(encodeURIComponent(p) + "=" + encodeURIComponent(obj[p]));
        }
      return str.join("&");
    };

    let toPass = {
      [props.group === true ? "GroupId" : "CompanyId"]: props.apiKey,
      TimeZoneDifferenceMinutes: 0,
      CompanyGroupName: props.CompanyGroupName,
      MailFrequency: jaDetails.MailFrequency,
      EmailAddress: jaDetails.EmailAddress,
      FirstName: jaDetails.FirstName,
      LastName: jaDetails.LastName,
      ReturnToSearchURL: props.ReturnToSearchURL,
      VacancyPageBaseURL: props.VacancyPageBaseURL,
      OptInLink: props.OptInLink,
      UnsubscribeLink: props.UnsubscribeLink,
      PostingType: "Careers",
      Location:
        jaDetails.Location[0] && jaDetails.Location[0] === "All"
          ? ""
          : jaDetails.Location.join("|"),
      Region:
        jaDetails.Region[0] && jaDetails.Region[0] === "All"
          ? ""
          : jaDetails.Region.join("|"),
      Industry:
        jaDetails.Industry[0] && jaDetails.Industry[0] === "All"
          ? ""
          : jaDetails.Industry.join("|"),
      Category:
        jaDetails.Category[0] && jaDetails.Category[0] === "All"
          ? ""
          : jaDetails.Category.join("|"),
      Distance: 15,
      Unit: "Miles",
    };

    fetchJsonp(
      props.siteConfig.sfAPIEndpoint +
        "/CareerPage/SubscribeForJobAlert?id=" +
        props.apiKey +
        "&" +
        serialize(toPass),
      {
        timeout: 15000,
      }
    )
      .then(function (response) {
        props.trackEvent({
          eventCategory: "JobAlertsPage",
          eventAction: "subscriptionUpdated",
        });
        return response.json();
      })
      .then(function (result) {
        setApiResponse(result);
      });
  };

  const updateField = (name, val) => {
    let temp = { ...jaDetails };

    temp[name] = val;

    setJaDetails(temp);
  };

  const addFilter = (label, item) => {
    let temp = { ...jaDetails };

    if (temp[label].includes(item)) {
      temp[label].splice(temp[label].indexOf(item), 1);
    } else {
      if (item === "All") {
        temp[label] = ["All"];
      } else {
        temp[label].push(item);
      }
    }

    setJaDetails(temp);
  };

  const isItemChecked = (label, item) => {
    if (jaDetails[label].includes(item)) {
      return true;
    } else {
      return false;
    }
  };

  const renderApiData = (label, state) => {
    return (
      <div className="col-12 col-xs-12 col-md-4 ja-filters-check-wrap">
        <div className="ja-filters-check-inner-wrap">
          <div className="ja-filters-section-title">
            <label>{label}:</label>
          </div>
          {state &&
            Array.isArray(state) &&
            state.map((item, i) => (
              <div className="vf-alerts-check-wrap">
                <input
                  id={label + "_" + item}
                  checked={isItemChecked(label, item)}
                  onChange={() => addFilter(label, item)}
                  type="checkbox"
                  className="vf-alerts-check"
                  name={label}
                  value={item}
                  title={`Select ${label}`}
                  disabled={jaDetails[label].includes("All") && item !== "All"}
                />
                <label
                  className="vf-alerts-check-label"
                  htmlFor={label + "_" + item}
                >
                  {" "}
                  {item}
                </label>
              </div>
            ))}
        </div>
      </div>
    );
  };

  return (
    <div className="VF_Alerts_Wrap smartfeed-forms-form">
      <div className="VF_Alerts_Inner_Wrap smartfeed-forms-page">
        <fieldset className="smartfeed-forms-fieldset">
          <legend>Your details</legend>

          <div className="row-fluid">
            <div className="smartfeed-forms-container col-md-12">
              <div className=" smartfeed-forms-field firstname firstname mandatory">
                <label
                  htmlFor="job-alerts-firstname"
                  className="smartfeed-forms-label"
                >
                  First name{" "}
                </label>
                <input
                  id="job-alerts-firstname"
                  className="VF-form-control onchange-search form-control"
                  onChange={(e) => updateField("FirstName", e.target.value)}
                  placeholder={"Please enter your first name"}
                  title={"Enter your first name"}
                ></input>
              </div>
              <div className=" smartfeed-forms-field lastname lastname mandatory alternating">
                <label
                  for="job-alerts-lastname"
                  className="smartfeed-forms-label"
                >
                  Last name{" "}
                </label>
                <input
                  id="job-alerts-lastname"
                  className="VF-form-control onchange-search form-control"
                  onChange={(e) => updateField("LastName", e.target.value)}
                  placeholder={"Please enter your last name"}
                  title={"Enter your last name"}
                ></input>
              </div>
              <div className=" smartfeed-forms-field email perplextextfield mandatory">
                <label for="job-alerts-email" className="smartfeed-forms-label">
                  Email{" "}
                </label>
                <input
                  id="job-alerts-email"
                  className="VF-form-control onchange-search form-control"
                  onChange={(e) => updateField("EmailAddress", e.target.value)}
                  placeholder={"Please enter your email address"}
                  title={"Enter your email address"}
                ></input>
              </div>
            </div>
          </div>
        </fieldset>
        <fieldset className="smartfeed-forms-fieldset">
          <legend>Roles you're interested in</legend>

          <p>
            Select the options that you'll like to recieve notifications for:
          </p>

          {props.category && (
            <div className="smartfeed-forms-ja-filter-wrap">
              <strong>Category:</strong>
              <div className="row-fluid">
                <div className="smartfeed-forms-container col-md-12">
                  {props.category && (
                    <>{renderApiData("Category", categoryData)}</>
                  )}
                </div>
              </div>
            </div>
          )}
          {props.region && (
            <div className="smartfeed-forms-ja-filter-wrap">
              <strong>Region:</strong>
              <div className="row-fluid">
                <div className="smartfeed-forms-container col-md-12">
                  {props.region && <>{renderApiData("Region", regionData)}</>}
                </div>
              </div>
            </div>
          )}
          {props.location && (
            <div className="smartfeed-forms-ja-filter-wrap">
              <strong>Location:</strong>
              <div className="row-fluid">
                <div className="smartfeed-forms-container col-md-12">
                  {props.location && (
                    <>{renderApiData("Location", locationData)}</>
                  )}
                </div>
              </div>
            </div>
          )}
          {props.industry && (
            <div className="smartfeed-forms-ja-filter-wrap">
              <strong>Industry:</strong>
              <div className="row-fluid">
                <div className="smartfeed-forms-container col-md-12">
                  {props.industry && (
                    <>{renderApiData("Industry", industryData)}</>
                  )}
                </div>
              </div>
            </div>
          )}
          {props.jobType && (
            <div className="smartfeed-forms-ja-filter-wrap">
              <strong>Job Type and Time:</strong>
              <div className="row-fluid">
                <div className="smartfeed-forms-container col-md-12">
                  {props.jobType && (
                    <>{renderApiData("JobType", jobTypeData)}</>
                  )}
                </div>
              </div>
            </div>
          )}
          <hr />
          {props.jobTime && (
            <div className="smartfeed-forms-ja-filter-wrap">
              <div className="row-fluid">
                <div className="smartfeed-forms-container col-md-12">
                  {props.jobTime && (
                    <>{renderApiData("JobTime", jobTimeData)}</>
                  )}
                </div>
              </div>
            </div>
          )}
        </fieldset>
        <fieldset className="smartfeed-forms-fieldset">
          <legend>Job alert subscription statement</legend>

          <div className="row-fluid">
            <div className="smartfeed-forms-container col-md-12">
              <div className="gdprStatement">
                <div
                  dangerouslySetInnerHTML={{
                    __html: GDPRStatement.Statement,
                  }}
                />
                <div
                  dangerouslySetInnerHTML={{
                    __html: GDPRStatement.GDPRCustomMessage,
                  }}
                />
              </div>

              <input
                type="checkbox"
                id="job-alerts-gdpr"
                name="gdpr"
                value="acceptGDPR"
                className="acceptGDPR_Check"
                checked={jaDetails.acceptGDPR}
                onChange={() => changeGDPRVal()}
                title="Agree to the job alert subscription statement"
              />
              <label htmlFor="job-alerts-gdpr">
                I have read and understood this statement and I am happy to
                proceed.
              </label>
            </div>
          </div>
        </fieldset>

        <div className="job-alert-actions">
          {GDPRStatement &&
          jaDetails.acceptGDPR === true &&
          jaDetails.Category.length > 0 &&
          jaDetails.EmailAddress !== "" &&
          jaDetails.FirstName !== "" &&
          jaDetails.LastName !== "" ? (
            <div className="subscrube-btn-wrap">
              {apiResponse === "OK" && (
                <span>You have successfully signed up to Job Alerts</span>
              )}
              {apiResponse === "Error" && (
                <span>There was an error, please try again</span>
              )}
              {apiResponse === "Updated" && (
                <span>
                  You have successfully updated your Job Alerts subscription
                </span>
              )}
              {apiResponse === "Pending" && (
                <span>
                  You have successfully signed up to Job Alerts, please check
                  your email inbox to confirm your subscription.
                </span>
              )}
              {apiResponse !== "OK" &&
                apiResponse !== "Updated" &&
                apiResponse !== "Pending" && (
                  <button
                    className="nav-action-sm"
                    onClick={() => subscribeToJobAlerts()}
                  >
                    Subscribe for job alerts
                  </button>
                )}
            </div>
          ) : (
            <div className="subscrube-btn-wrap">
              <button className="nav-action-sm" disabled>
                Please enter your details to subscribe
              </button>
            </div>
          )}
        </div>
      </div>
    </div>
  );
};

export default SmartFeedJobAlerts;
